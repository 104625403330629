import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalHapus: false,

    rwgaji: {
      id: '',
      tmt: '',
      suratKeputusan: {
        pejabat: '',
        nomor: '',
        tanggal: ''
      },
      masaKerja: {
        tahun: '',
        bulan: ''
      },
      gajiPokok: '',
      golongan: {
        id: '',
        nama: ''
      },
      userId: null,
      NCSISTIME: null
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalHapus = value
    },

    hapusModal(state, value) {
      state.rwgaji = value
    },
  }
})