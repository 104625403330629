<template>
  <div>
    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <div>
      <v-data-table
        :headers="headers"
        :items="rwgajis"
        :items-per-page="-1"
        :mobile-breakpoint="0"
        :loading="ldata"
        hide-default-footer
        style="text-overflow: ellipsis; white-space: nowrap"
      >
        <template v-slot:top>
          <rwgajiModalEdit />
          <rwgajiModalHapus />
        </template>
        <template v-slot:[`item.pejabat`]="{ item }">
          Pejabat : {{ item.suratKeputusan.pejabat }} <br />
          Nomor : {{ item.suratKeputusan.nomor }} <br />
          Tanggal : {{ item.suratKeputusan.tanggal }}
        </template>
        <template v-slot:[`item.masakerja`]="{ item }">
          {{ item.masaKerja.tahun }} - {{ item.masaKerja.bulan }} <br />
          {{ item.pangkat }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small left @click="editItem(item)"> mdi-pencil </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import rwgajiModalEdit from "@/components/asn/profil/rwgaji/ModalEdit";
import rwgajiModalHapus from "@/components/asn/profil/rwgaji/ModalHapus";

import refreshView from "@/store/asn/rwgaji/viewRwgaji";
import modal_editState from "@/store/asn/rwgaji/modal_edit";
import modal_hapusState from "@/store/asn/rwgaji/modal_hapus";

export default {
  components: {
    rwgajiModalEdit,
    rwgajiModalHapus,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },

      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    dialog: false,
    get_nip: "",

    ldata: true,

    rwgajis: [],
    rwgaji: {},

    headers: [
      {
        text: "Golongan",
        align: "start",
        sortable: false,
        value: "golongan.nama",
      },
      { text: "TMT", align: "start", sortable: false, value: "tmt" },
      {
        text: "Pejabat yang Menetapkan, Nomor dan Tanggal Surat Keputusan",
        value: "pejabat",
      },
      { text: "Masa Kerja / Pangkat", value: "masakerja" },
      { text: "Gaji", value: "gajiPokok" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "80px",
      },
    ],

    //Kebutuhan Edit dan Hapus
    editedIndex: -1,
    hapusIndex: -1,
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_ASN + "kgb/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.ldata = false;
          refreshView.commit("refreshData", false);
          this.rwgajis = response.data.mapData.RwKGB;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.rwgajis.indexOf(item);
      this.rwgaji = Object.assign({}, item);
      modal_editState.commit("toggleModal", true);
      modal_editState.commit("editModal", Object.assign({}, item));
    },

    deleteItem(item) {
      this.hapusIndex = this.rwgajis.indexOf(item);
      this.rwgaji = Object.assign({}, item);
      modal_hapusState.commit("toggleModal", true);
      modal_hapusState.commit("hapusModal", Object.assign({}, item));
    },
  },
};
</script>
